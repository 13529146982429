import cookiesParams from './cookiesParams';

const SECURE = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test';

export function deleteCookie(name) {
  // Include subdomains to delete the cookie.
  const domain = `.${window.location.hostname}`;
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;max-age=0;domain=${domain};secure=${SECURE};path=/;samesite=none`;
}

export function deleteAuthCookie() {
  deleteCookie(cookiesParams.auth.name);
}

/* GP-608: Deprecated. The original intention was to delete the cookie by setting its expire to a past date and/or setting the max-age to 0.
 * However, this does not seem to work in some browsers, such as Firefox. Expired cookies were not removed from the browser.
 * Instead, the value is now set as false, and expiry set as empty string (thus converting it to a session cookie).
 */
export function deleteReloadBookingCookie() {
  deleteCookie(cookiesParams.loadBookingsFlag.name);
}
