import dialogsNames from 'utils/constants/dialogs/dialogsNames';
import { guestHaveMoreThanOneBooking } from 'utils/booking';
import { setBookingsDirty, updateBookingSession } from 'utils/bookingSession';
import { hide, showGuestSuccess, showSuccess } from 'state/modules/dialog';
import { clearProfile, clearTemplates } from 'state/modules/guest';
import { deleteReloadBookingCookie } from 'utils/cookies/deleteCookie';
import { loadBookings } from './actions';
import TYPES from './types';
import { showGuestError } from '../dialog/actions';

const {
  ADD_GUEST_SUCCESS,
  LOAD_SUCCESS,
  LOAD_SUCCESS_SESSION_STORAGE,
  REMOVE_BOOKER_SUCCESS,
  REMOVE_GUEST_SUCCESS,
  UPDATE_GUEST_SUCCESS,
  UPDATE_GUEST_FAIL,
} = TYPES;

export default ({ dispatch, getState }) => next => action => {
  const { result, type, successMessage } = action;

  switch (type) {
    case ADD_GUEST_SUCCESS: {
      dispatch(hide(dialogsNames.GUEST_FORM));
      dispatch(hide(dialogsNames.GUEST_EXISTING));
      dispatch(showGuestSuccess({ messageId: 'dialog.message.add.guest.success', validation: result?.validation }));
      dispatch(clearTemplates());
      break;
    }
    case LOAD_SUCCESS:
    case LOAD_SUCCESS_SESSION_STORAGE: {
      updateBookingSession(result);
      deleteReloadBookingCookie();
      break;
    }
    case REMOVE_BOOKER_SUCCESS: {
      setBookingsDirty();
      dispatch(loadBookings());
      dispatch(showSuccess(successMessage));
      break;
    }
    case REMOVE_GUEST_SUCCESS: {
      dispatch(hide(dialogsNames.GUEST_FORM));
      dispatch(showGuestSuccess({ messageId: 'dialog.message.remove.guest.success', validation: result?.validation }));
      break;
    }
    case UPDATE_GUEST_SUCCESS: {
      const { booking: bookingState, guest: guestState } = getState();
      const upcomingBookings = bookingState?.bookings?.upcoming ?? [];
      const updatedGuestId = result?.cmsFolioGuest?.id;
      const profileGuestIds = guestState?.profile?.cmsGuestIds ?? [];

      // If theres a verification error, display it
      if (result.validation?.errors?.length > 0) {
        dispatch(showGuestError({ title: 'dialog.message.edit.guest.fail', message: result.validation.errors }));
      }

      // Reload the bookings if the updated guest in more than 1 upcoming bookings.
      if (guestHaveMoreThanOneBooking(updatedGuestId, upcomingBookings)) {
        setBookingsDirty();
        dispatch(loadBookings());
      }
      // Reset the profile data when the updated Guest ID is related to the profile
      const isProfileTemplateUpdate = profileGuestIds.includes(updatedGuestId);
      if (isProfileTemplateUpdate) {
        dispatch(clearProfile());
      }
      // Display success and clear the guest templates
      dispatch(hide(dialogsNames.GUEST_FORM));
      dispatch(showGuestSuccess({ messageId: 'dialog.message.edit.guest.success', validation: result?.validation }));
      dispatch(clearTemplates());
      break;
    }
    case UPDATE_GUEST_FAIL: {
      dispatch(showGuestError({ title: 'dialog.message.edit.guest.fail', message: result.validation.errors }));
      break;
    }
    default:
      break;
  }
  next(action);
};
