import dialogsNames from 'utils/constants/dialogs/dialogsNames';
import types from './types';

const { SHOW, HIDE } = types;

function show(dialogName, dialogProps) {
  return { type: SHOW, dialogName, dialogProps };
}

export function hide(dialogName) {
  return { type: HIDE, dialogName };
}

export function showExistingGuests(bookingId, currentGuests) {
  return show(dialogsNames.GUEST_EXISTING, { bookingId, currentGuests });
}

export function showError({ code, message, title }) {
  return show(dialogsNames.ERROR, { code, message, title });
}

export function showGuestError({ title, message }) {
  return show(dialogsNames.ERROR, { title, message });
}

export function showGuestSuccess({ messageId, validation }) {
  return show(dialogsNames.GUEST_SUCCESS, { messageId, validation });
}

// Action used to open a Guest input form in a Dialog
// If guestId the form will be empty to create a new guest
export function showGuestForm(bookingId, guestId, title, onClose) {
  return show(dialogsNames.GUEST_FORM, { bookingId, guestId, title, onClose });
}

export function showGuestsMaximum(accomDetails, bookingId, currentGuests, isNewGuest) {
  return show(dialogsNames.GUEST_MAXIMUM, { accomDetails, bookingId, currentGuests, isNewGuest });
}

export function showLoginFail() {
  return show(dialogsNames.LOGIN_FAIL);
}

export function showSuccess(successMessage) {
  return show(dialogsNames.SUCCESS, { successMessage });
}

export function showChangePassword() {
  return show(dialogsNames.CHANGE_PASSWORD);
}
