import { showGuestSuccess } from 'state/modules/dialog';
import guestTypes from './types';
import { showGuestError } from '../dialog/actions';

export default ({ dispatch }) => next => action => {
  const { type, result } = action;
  const response = next(action);

  switch (type) {
    case guestTypes.UPDATE_SUCCESS: {
      if (result.validation?.errors?.length > 0) {
        dispatch(showGuestError({ title: 'dialog.message.edit.guest.fail', message: result.validation.errors }));
      }
      else {
        dispatch(showGuestSuccess({ messageId: 'dialog.message.detailsChange.success', validation: result.validation }));
      }
      break;
    }
    case guestTypes.UPDATE_FAIL: {
      if (result?.validation?.errors?.length > 0) {
        dispatch(showGuestError({ message: result.validation.errors, title: 'dialog.message.edit.guest.fail' }));
      }
      break;
    }
    default:
      break;
  }

  return response;
};
