import React from 'react';
import loadable from '@loadable/component';

import Loader from 'components/Loader';
import { ErrorBoundary } from 'components/Errors';

const Maintenance = loadable(() => import(/* webpackChunkName: "Maintenance" */ './Maintenance'), {
  fallback: <Loader visible />,
});

const asyncMaintenance = () => (
  <ErrorBoundary>
    <Maintenance />
  </ErrorBoundary>
);

export default asyncMaintenance;
