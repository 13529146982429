import cookiesParams from './cookiesParams';

const SECURE = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test';

export function getCookieDomain(hostname) {
  if (hostname === 'localhost') {
    return hostname;
  }

  // split the hostname to the domains inside it
  const domains = hostname.split('.');
  const topLevelDomain = domains.pop(); // .com or .localhost
  const mainDomain = domains.pop(); // eg. nisekocentral or kiniseko

  return [mainDomain, topLevelDomain].join('.');
}

export function setCookie(name, value, expireAt) {
  const domain = getCookieDomain(window.location.hostname);
  document.cookie = `${name}=${value};expires=${expireAt};domain=${domain};secure=${SECURE};path=/;samesite=none`;
}

export function setAcceptCookie() {
  const futureDate = new Date();
  futureDate.setHours(futureDate.getHours() + cookiesParams.consent.maxAgeHours);
  setCookie(cookiesParams.consent.name, cookiesParams.consent.value, futureDate);
}

export function setAuthCookie(token, expireAt) {
  const gmtExpireAt = new Date(expireAt);
  setCookie(cookiesParams.auth.name, token, gmtExpireAt.toUTCString());
}

export function setLocaleCookie(locale) {
  setCookie(cookiesParams.locale.name, locale);
}

export function setReloadBookingsCookie(value) {
  setCookie(cookiesParams.loadBookingsFlag.name, value);
}
